import React from "react";
import { MdPlace, MdLinearScale } from "react-icons/md";

import "./segment-info-bar.css";

import SegmentRatingsChart from "../segment-ratings-chart/SegmentRatingsChart";

import { getColor } from "../../utils/layers";

export interface SegmentFeatureProperties {
  avg_rating?: number;
  color?: string;
  level?: number;
  ratings?: number[];
  segment?: {
    name?: string;
    lanes?: string;
    reversed?: boolean;
    longitude?: number[];
    id?: string;
    length?: number | string;
    oneway?: string;
    latitude?: number[];
    osm_version?: string;
    highway?: string;
  };
}

interface SegmentInfoBarProps {
  segmentProperties: SegmentFeatureProperties;
}

const SegmentInfoBar = ({ segmentProperties }: SegmentInfoBarProps) => (
  <div className="segment-info-bar">
    <div className="segment-info-bar__section segment-info-bar__street-name">
      <MdPlace />
      <span>{segmentProperties?.segment?.name}</span>
    </div>
    {segmentProperties?.ratings && (
      <div className="segment-info-bar__section segment-info-bar__rating_chart">
        <SegmentRatingsChart ratings={segmentProperties?.ratings} />
      </div>
    )}
    <div className="segment-info-bar__section segment-info-bar__details">
      {segmentProperties?.avg_rating && (
        <div className="segment-info-bar__details__avg-rating">
          <span
            className="segment-info-bar__details__avg-rating__dot"
            style={{
              backgroundColor: getColor(
                Math.floor(segmentProperties?.avg_rating)
              )
            }}
          ></span>
          Avg. Rating:&nbsp;
          <span className="segment-info-bar__details__avg-rating__value">
            {segmentProperties?.avg_rating}
          </span>
        </div>
      )}
      <div className="segment-info-bar__details__length">
        <MdLinearScale />
        &nbsp;Length:&nbsp;
        <span className="segment-info-bar__details__length__value">
          {segmentProperties?.segment?.length}
        </span>
      </div>
    </div>
  </div>
);

export default SegmentInfoBar;
