import LayerDataType from "../constants/layerTypes";
import {
  LayerLevelPalettes,
  COLOR_PALETTES,
  RB_IMAGE_LOGGER_BLUE,
} from "../constants/colors";
import { MapImages } from "../constants/images";
import { LayerProps } from "../components/Layer";
import { LayerGroups } from "../containers/layer-groups/LayerGroups";

export const getLayerById = (layers: LayerProps[], layerId: string) =>
  layers.find(({ id }) => id === layerId);

export const getLayerDataType = (layers: LayerProps[], layerId: string) => {
  const layer = getLayerById(layers, layerId);
  return layer?.dataType;
};

export const groupLayers = (layers: LayerProps[]) => {
  const layerGroups: LayerGroups = {};
  for (const layer of layers) {
    if (layer.dataType) {
      if (!layerGroups[layer.dataType]) {
        layerGroups[layer.dataType] = [];
      }
      layerGroups[layer.dataType].push(layer);
    }
  }
  return layerGroups;
};

export const getColor = (
  level?: number,
  palette: LayerLevelPalettes = LayerLevelPalettes.DEFAULT
): string => {
  const colors = COLOR_PALETTES[palette];
  return colors && level && !isNaN(level)
    ? colors[level - 1]
    : RB_IMAGE_LOGGER_BLUE;
};

export const isSidewalkPoint = (layerSource: string) => {
  return layerSource.search("sidewalk_point") !== -1;
};

const getType = (type: LayerDataType): string | undefined => {
  switch (type) {
    case LayerDataType.SEGMENT:
      return "line";
    case LayerDataType.POINT:
    case LayerDataType.CRACKSEAL:
    case LayerDataType.ROADSENSEPOINT:
      return "circle";
    case LayerDataType.POTHOLE:
      return "symbol";
    default:
      return undefined;
  }
};

const getSource = (uploadId: string): object => ({
  type: "vector",
  url: `mapbox://${uploadId}`,
});

const buildLayer = ({
  type,
  uploadId,
  level,
  layerSource,
}: any): LayerProps => {
  return {
    id: type === "highlight_points" ? `${layerSource}-highlights` : layerSource,
    name: layerSource,
    visible: true,
    colorPalette: LayerLevelPalettes.DEFAULT,
    dataType: type,
    level: (type !== "roadSensePoint" || isSidewalkPoint(layerSource)) && level,
    type: getType(type),
    sourceLayer: layerSource,
    source: getSource(uploadId),
  };
};

export const getPaintProps = (
  type?: LayerDataType,
  level?: number,
  palette: LayerLevelPalettes = LayerLevelPalettes.DEFAULT
): object | undefined => {
  const color = getColor(level, palette);
  switch (type) {
    case LayerDataType.SEGMENT:
      return {
        "line-color": color,
        "line-opacity": 1.0,
        "line-width": {
          base: 1,
          stops: [
            [10, 2],
            [15, 4],
          ],
        },
      };
    case LayerDataType.POINT:
    case LayerDataType.CRACKSEAL:
    case LayerDataType.ROADSENSEPOINT:
      return {
        "circle-color": color,
        "circle-opacity": {
          base: 1,
          stops: [
            [0, 1],
            [22, 1],
          ],
        },
        "circle-radius": {
          base: 1,
          stops: [
            [12, 3],
            [18, 5],
          ],
        },
      };
    default:
      return undefined;
  }
};

export const getLayoutProps = (
  type?: LayerDataType,
  visible = true
): object | undefined => {
  switch (type) {
    case LayerDataType.POINT:
    case LayerDataType.CRACKSEAL:
    case LayerDataType.ROADSENSEPOINT:
      return {
        visibility: visible ? "visible" : "none",
      };
    case LayerDataType.SEGMENT:
      return {
        visibility: visible ? "visible" : "none",
        "line-join": "round",
        "line-cap": "round",
      };
    case LayerDataType.POTHOLE:
      return {
        visibility: visible ? "visible" : "none",
        "icon-image": MapImages.POTHOLE,
        "icon-allow-overlap": true,
        "icon-size": {
          base: 0.5,
          stops: [
            [13.5, 0.5],
            [23, 1],
          ],
        },
        "icon-anchor": "bottom",
        "icon-rotation-alignment": "viewport",
        "icon-pitch-alignment": "viewport",
      };
    default:
      return undefined;
  }
};

const buildLayerData = (layers: any[]) => {
  return layers.map(layer => buildLayer(layer));
};

export default buildLayerData;
