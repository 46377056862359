import React, { useState } from "react";
import ratingsExplain from "../../images/ratings-explain.png";
import questionMark from "../../images/questionMark.svg";
import "./ratingsHelpModal.css";

const RatingsHelpModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="tooltip" onClick={() => setIsModalOpen(true)}>
        <span className="tooltiptext">Help</span>

        <img className="question-mark" src={questionMark} alt="question-mark" />
      </div>
      {isModalOpen && (
        <div onClick={() => setIsModalOpen(false)} className="help-modal">
          <div className="help-modal-content">
            <div
              onClick={() => setIsModalOpen(false)}
              className="help-close-button"
            >
              X
            </div>
            <p>
              After identifying the road surface distresses in an image,
              RoadBotics&apos; algorithms automatically generate a 1-5 score
              (1=High Quality, 5=Very Poor Quality) for that particular image,
              which represents approximately a 3-meter (10-foot) section of a
              roadway. This condition rating is generated based on the type,
              frequency, and density of distresses.
            </p>
            <img
              src={ratingsExplain}
              alt="ratings-explanation"
              className="help-modal-image"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RatingsHelpModal;
