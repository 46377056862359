import React from "react";
import * as MapboxGL from "mapbox-gl";
import { LayerGroupStore, LayerGroupStoreInterface } from "../../store/store";

import "./Points.css";

import { LayerProps } from "../../components/Layer";
import { LayerGroupProps } from "../layer-groups/LayerGroups";
import GenericLayerGroup from "../layer-groups/GenericLayerGroup";
import { PointFeatureProperties } from "../point-view-modal/PointViewModal";

import {
  findSegmentPointsFromPoint,
  findPointIndexInSegment,
  transformToPointModalPoints,
} from "../../utils/map";

/* Event handler for click on points layer */
export const handlePointClick = (
  map: MapboxGL.Map,
  pointLayers: LayerProps[],
  lngLat: MapboxGL.LngLat,
  clickedPoint: MapboxGL.MapboxGeoJSONFeature
) => {
  // set up zoom handler to rum after we zoom in to get the clicked feature
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // eslint-disable-next-line no-unused-vars
  map.once("moveend", zoomEvent => {
    // grab the first point from the queried features

    if (clickedPoint) {
      const filteredSegmentFeatures = findSegmentPointsFromPoint(
        map,
        clickedPoint,
        pointLayers
      );

      // get the index of the clicked point
      const clickedPointIndex = findPointIndexInSegment(
        filteredSegmentFeatures,
        clickedPoint
      );

      const segmentFeatureProperties: PointFeatureProperties[] = transformToPointModalPoints(
        filteredSegmentFeatures
      );

      const updateSegmentFeatureProperties = (
        state: LayerGroupStoreInterface
      ) => {
        state.activePointSegmentFeatureProperties = segmentFeatureProperties;
      };

      if (clickedPointIndex !== -1) {
        // prepare the data to be shown in the virtual driver view

        const updateSegmentPointIndex = (state: LayerGroupStoreInterface) => {
          state.activePointSegmentIndex = clickedPointIndex;
        };

        LayerGroupStore.update([
          updateSegmentFeatureProperties,
          updateSegmentPointIndex,
        ]);
      } else {
        const updateSegmentPointIndex = (state: LayerGroupStoreInterface) => {
          state.activePointSegmentIndex = 0;
        };

        LayerGroupStore.update([
          updateSegmentFeatureProperties,
          updateSegmentPointIndex,
        ]);
      }
    }
  });

  // First fly to clicked area at a higher zoom level
  // The code to find the actual point will be kicked off,
  // once the zoom ends as defined above
  map.flyTo({ center: lngLat, zoom: 17 });
};

const Points = ({ layers }: LayerGroupProps) => (
  <GenericLayerGroup layers={layers} />
);

export default Points;
