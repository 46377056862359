import React from "react";
import * as MapboxGL from "mapbox-gl";
import { Image } from "react-mapbox-gl";

import { LayerProps } from "../../components/Layer";
import { LayerGroupProps } from "../layer-groups/LayerGroups";
import GenericLayerGroup from "../layer-groups/GenericLayerGroup";

import { LayerGroupStore, LayerGroupStoreInterface } from "../../store/store";

import { PointFeatureProperties } from "../point-view-modal/PointViewModal";

import {
  findSegmentPointsFromPoint,
  findPointIndexInSegment,
  transformToPointModalPoints,
} from "../../utils/map";

import { MapImages, MapImageFiles } from "../../constants/images";

/* Event handler for click on points layer */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const handlePotholeClick = (
  map: MapboxGL.Map,
  pointLayers: LayerProps[],
  clickedLayer: MapboxGL.MapboxGeoJSONFeature
) => {
  const clickedLayerCoords = JSON.parse(clickedLayer.properties?.nearest_node);

  // set up zoom handler to rum after we zoom in to get the clicked feature
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // eslint-disable-next-line no-unused-vars
  map.once("moveend", zoomEvent => {
    if (clickedLayer) {
      const filteredSegmentFeatures = findSegmentPointsFromPoint(
        map,
        clickedLayer,
        pointLayers
      );

      // get the index of the clicked point
      const clickedLayerIndex = findPointIndexInSegment(
        filteredSegmentFeatures,
        clickedLayer
      );

      const segmentFeatureProperties: PointFeatureProperties[] = transformToPointModalPoints(
        filteredSegmentFeatures
      );

      const updateSegmentFeatureProperties = (
        state: LayerGroupStoreInterface
      ) => {
        state.activePointSegmentFeatureProperties = segmentFeatureProperties;
      };

      if (clickedLayerIndex !== -1) {
        // prepare the data to be shown in the virtual driver view

        const updateSegmentPointIndex = (state: LayerGroupStoreInterface) => {
          state.activePointSegmentIndex = clickedLayerIndex;
        };
        LayerGroupStore.update([
          updateSegmentFeatureProperties,
          updateSegmentPointIndex,
        ]);
      } else {
        const updateSegmentPointIndex = (state: LayerGroupStoreInterface) => {
          state.activePointSegmentIndex = 0;
        };
        LayerGroupStore.update([
          updateSegmentFeatureProperties,
          updateSegmentPointIndex,
        ]);
      }
    }
  });

  map.flyTo({ center: clickedLayerCoords, zoom: 15 });
};

const Potholes = ({ layers }: LayerGroupProps) => (
  <>
    <Image id={MapImages.POTHOLE} url={MapImageFiles[MapImages.POTHOLE]} />
    <GenericLayerGroup layers={layers} />
  </>
);

export default Potholes;
