import React, { useEffect } from "react";
import * as MapboxGL from "mapbox-gl";

import { LayerGroupProps } from "../layer-groups/LayerGroups";
import GenericLayerGroup from "../layer-groups/GenericLayerGroup";
import Popup from "../../components/popup/Popup";
import SegmentInfoBar from "../../components/segment-info-bar/SegmentInfoBar";

import { LayerGroupStore, LineBarOpen } from "../../store/store";
import { meterToFeet } from "../../utils/metrics";

export const handleSegmentClick = (
  map: MapboxGL.Map,
  lngLat: MapboxGL.LngLat,
  clickedSegmentFeature?: MapboxGL.MapboxGeoJSONFeature
) => {
  LayerGroupStore.update(state => {
    state.activeSegmentProperties = {};
  });

  // zoom in before we try to get the feature clicked
  // eslint-disable-next-line no-unused-vars
  map.once("moveend", zoomEvent => {
    if (clickedSegmentFeature) {
      const {
        segment: segmentJSONString,
        ratings: ratingsJSONString,
        // eslint-disable-next-line @typescript-eslint/camelcase
        avg_rating,
        ...otherProperties
      } = clickedSegmentFeature?.properties || {};

      const { length: segmentLength, ...otherSegmentProperties } = JSON.parse(
        segmentJSONString
      );
      const updatedSegmentProperties = {
        ...otherSegmentProperties,
        length: `${meterToFeet(segmentLength, 2).scalar} Feet`,
      };

      const clickedSegmentProps = {
        ...otherProperties,
        // eslint-disable-next-line @typescript-eslint/camelcase
        avg_rating: avg_rating?.toFixed(2) || avg_rating,
        ratings: JSON.parse(ratingsJSONString),
        segment: updatedSegmentProperties,
      };

      LayerGroupStore.update(state => {
        state.activeSegmentProperties = clickedSegmentProps;
      });
    }
  });

  // First fly to clicked area at a higher zoom level
  // The code to find the actual point will be kicked off,
  // once the zoom ends as defined above
  map.flyTo({ center: lngLat, zoom: 17 });
};

const Segments = ({ layers }: LayerGroupProps) => {
  const isLineBarOpen = LineBarOpen.useState(s => s.isOpen);

  const activeSegmentProperties = LayerGroupStore.useState(
    state => state.activeSegmentProperties
  );

  useEffect(() => {
    if (Object.entries(activeSegmentProperties).length !== 0) {
      LineBarOpen.update(state => {
        state.isOpen = true;
      });
    }
  }, [activeSegmentProperties]);

  const handleCloseSegmentInfoPopup = (): void =>
    LineBarOpen.update(state => {
      state.isOpen = false;
    });

  const renderInfoBar = () => (
    <Popup isOpen={isLineBarOpen} onCloseModal={handleCloseSegmentInfoPopup}>
      <SegmentInfoBar segmentProperties={activeSegmentProperties} />
    </Popup>
  );

  const renderSegments = () => <GenericLayerGroup layers={layers} />;

  return (
    <>
      {renderSegments()}
      {renderInfoBar()}
    </>
  );
};

export default Segments;
