import React from "react";

import centerIcon from "../../images/centerIcon.png";

import "./center-button.css";

interface CenterButtonProps {
  handleClick: () => void;
  isAnalysisPresent: boolean;
}
const CenterButton = ({
  handleClick,
  isAnalysisPresent,
}: CenterButtonProps) => {
  return (
    <button
      onClick={handleClick}
      style={{ marginTop: isAnalysisPresent ? "140px" : "70px" }}
      className="center-icon-button"
    >
      <img className="center-icon" src={centerIcon} alt="center" />
    </button>
  );
};

export default CenterButton;
