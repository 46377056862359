import React from 'react';
import track from "react-tracking";

import 'normalize.css'; 
import "react-responsive-modal/styles.css";

import MapContainer from '../containers/map-container/MapContainer';

function App() {
  return (
    <MapContainer />
  );
}


export default track()(App);
