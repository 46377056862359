/**
 * largestRemainderRound will round each number in an array to the nearest
 * integer but make sure that the the sum of all the numbers still equals
 * desiredTotal. Uses Largest Remainder Method.  Returns numbers in order they
 * came.
 *
 * @param {number[]} numbers - numbers to round
 * @param {number} desiredTotal - total that sum of the return list must equal
 * @return {number[]} the list of rounded numbers
 * @example
 *
 * var numbers = [13.6263, 47.9896, 9.59600 28.7880]
 * largestRemainderRound(numbers, 100)
 *
 * // => [14, 48, 9, 29]
 *
 */

function getRemainder(number: number): number {
  const remainder = number - Math.floor(number);
  return parseFloat(remainder.toFixed(4));
}

export const largestRemainderRound = (
  numbers: number[],
  desiredTotal: number
): number[] => {
  const result = numbers
    .map((number, index) => {
      return {
        floor: Math.floor(number),
        remainder: getRemainder(number),
        index: index
      };
    })
    .sort((a, b) => {
      return b.remainder - a.remainder;
    });

  const lowerSum = result.reduce((sum, current) => {
    return sum + current.floor;
  }, 0);

  const delta = desiredTotal - lowerSum;

  for (let i = 0; i < delta; i++) {
    result[i] && result[i].floor++;
  }

  return result.sort((a, b) => a.index - b.index).map(result => result.floor);
};
