import React from "react";

import "./map-loading-indicator.css"

type MapLoadingIndicatorProps = {
  isFetchingMap: boolean;
  isFetchMapSuccessful?: boolean;
  children?: React.ReactNode;
};

const MapLoadingIndicator = ({
  isFetchingMap,
  isFetchMapSuccessful,
  children
}: MapLoadingIndicatorProps): React.ReactElement => {

  const renderMap = () => {
    if (isFetchMapSuccessful) return  (<>{children}</>);
    return (<p>The map is no longer available</p>)

  };

  return (
    <div className="map-loading-indicator">
      {
        isFetchingMap
          ? <p>Loading Map...</p>
          : renderMap()
      }
    </div>
  );
};

export default MapLoadingIndicator;
