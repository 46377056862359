import React, { ReactElement } from "react";
import { Modal } from "react-responsive-modal";

import SlideShow, { SlideShowSlides } from "../slide-show/SlideShow";
import { MdSchedule, MdNavigation, MdClose } from "react-icons/md";
import { RatingImages } from "../../constants/images";
import { getColor } from "../../utils/layers";

export interface PointFeatureProperties {
  segment?: string;
  timestamp?: string;
  bearing?: string | null;
  href?: string;
  rating?: number;
  primaryDistresses?: {
    hasFatigueCracking: boolean;
    hasPatchSeal: boolean;
    hasPavementDistortions: boolean;
    hasPothole: boolean;
    hasSurfaceDeterioration: boolean;
    hasTransLongCracking: boolean;
  };
}

export const PointFeatureTitles: {
  [key in keyof PointFeatureProperties]: {
    title: string;
    icon: JSX.Element;
  };
} = {
  timestamp: {
    title: "Image Date",
    icon: <MdSchedule />,
  },
  bearing: {
    title: "Direction",
    icon: <MdNavigation />,
  },
};

interface PointViewModalProps {
  isOpen: boolean;
  initialPointIndex: number;
  points: PointFeatureProperties[];
  onCloseModal: () => void;
  handleAnimationEnd: () => void;
}

const PointViewModal = ({
  isOpen,
  points,
  onCloseModal,
  initialPointIndex,
  handleAnimationEnd,
}: PointViewModalProps): ReactElement => {
  const generateImageHeader = (rating?: number): ReactElement =>
    rating !== undefined && !isNaN(rating) && RatingImages[rating - 1] ? (
      <div className="point-rating-container">
        <hr style={{ backgroundColor: getColor(rating) }} />
        <img src={RatingImages[rating - 1]} alt={`Rating ${rating}`} />
        <hr style={{ backgroundColor: getColor(rating) }} />
      </div>
    ) : (
      <p>
        <i>No Rating available</i>
      </p>
    );

  const slides = points.map(point => {
    const { href, rating, primaryDistresses, ...otherProperties } = point;
    const otherPropertyKeys = Object.keys(otherProperties) as Array<
      keyof PointFeatureProperties
    >;
    const slide: SlideShowSlides = {
      image: href || "",
      details: otherPropertyKeys.map((key: keyof PointFeatureProperties) => ({
        value: point[key] as string,
        title: PointFeatureTitles[key]?.title,
        icon: PointFeatureTitles[key]?.icon,
      })),
      primaryDistresses: primaryDistresses,
      header: rating && generateImageHeader(rating),
    };
    return slide;
  });

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      center
      classNames={{
        modal: "modal",
        overlay: "modal-overlay",
        closeButton: "modal-close-button",
      }}
      closeIcon={<MdClose />}
      onAnimationEnd={handleAnimationEnd}
    >
      {slides.length > 0 && (
        <SlideShow slides={slides} initialSlide={initialPointIndex} />
      )}
    </Modal>
  );
};

export default PointViewModal;
