import React, { useCallback, useEffect, useState } from "react";
import { useTracking } from "react-tracking";
import * as MapboxGL from "mapbox-gl";
import { ZoomControl } from "react-mapbox-gl";
import queryString from "query-string";

import Map from "../../config/reactMapboxGL";
import LayerGroupsComponent, { LayerGroups } from "../layer-groups/LayerGroups";
import { LayerProps } from "../../components/Layer";
import RoadBoticsLogo from "../../components/roadbotics-logo/RoadBoticsLogo";
import CenterButton from "../../components/center-button/CenterButton";
import MapLoadingIndicator from "../../components/map-loading-indicator/MapLoadingIndicator";

import { fetchMapData } from "../../api/roadwayAPI";
import buildLayers, { groupLayers } from "../../utils/layers";

import { handleMapClick } from "./mapEvents";

import onMount from "../../hooks/useMount";

import AnalysisDiv from "../../components/AnalysisDiv/AnalysisDiv";
import RatingsHelpModal from "../ratings-help-modal/ratingsHelpModal";

type boundsType = [[number, number], [number, number]];
type queryParams = {
  id: string;
  bounds: number[];
};

const MapContainer = () => {
  const tracking = useTracking();
  const { id: mapId, bounds: mapBounds } = queryString.parse(
    document.location.search,
    { arrayFormat: "comma", parseNumbers: true }
  ) as queryParams;

  const defaultBounds = [0, 0, 0, 0];

  const [swLat, swLng, neLat, neLng] = mapBounds || defaultBounds;

  const initialMapBounds: boundsType = [
    [swLat, swLng],
    [neLat, neLng],
  ];

  const initalLayers: LayerProps[] = [];
  const initialLayerGroup: LayerGroups = {};
  const initialAnalysis = {
    networkScore: 0,
    networkLength: 0,
    potholeCount: 0,
  };

  const [bounds, setBounds] = useState(initialMapBounds);
  const [mapState, updateMapState] = useState(
    (null as unknown) as MapboxGL.Map
  );
  const [analysisObj, setAnalysisObj] = useState(initialAnalysis);
  const [layers, setLayers] = useState(initalLayers);
  const [layerGroups, setLayerGroups] = useState(initialLayerGroup);
  const [isFetchingMap, setIsFetchingMap] = useState(false);
  const [isFetchMapSuccessful, setIsFetchMapSuccessful] = useState(false);
  const [mapStyle, setMapStyle] = useState(
    "mapbox://styles/mdickson/cjdklaqf3k9v92rpmgu9zjakl"
  );

  const loadMapData = async () => {
    if (mapId) {
      tracking.trackEvent({
        event: "mouse-click",
        action: `map-data-loading`,
        mapId: mapId,
      });
      setIsFetchingMap(true);
      try {
        const {
          layers,
          scan,
          map: { style, networkLength, networkScore, potholeCount },
        } = await fetchMapData(mapId);
        setIsFetchMapSuccessful(true);
        setAnalysisObj({
          networkLength: networkLength ? parseFloat(networkLength) : 0,
          networkScore: networkScore ? parseFloat(networkScore) : 0,
          potholeCount: potholeCount ? potholeCount : 0,
        });
        if (style) setMapStyle(style);
        const [
          boundsSWLat,
          boundsSWLng,
          boundsNELat,
          boundsNELng,
        ]: number[] = scan?.defaults?.bounds
          ? scan.defaults.bounds
          : initialMapBounds;
        setBounds([
          [boundsSWLat, boundsSWLng],
          [boundsNELat, boundsNELng],
        ]);
        const layerData = buildLayers(layers);
        const layerGroups = groupLayers(layerData);
        setLayers(layerData);
        setLayerGroups(layerGroups);
      } catch (e) {
        console.error(e);
      } finally {
        setIsFetchingMap(false);
      }
    } else {
      console.error("Invalid map id");
    }
  };

  const loadMapBounds = useCallback(() => {
    if (layers && mapId && mapState && !isFetchingMap) {
      // have to have a setTimeout here to prevent the mapState.getSource from
      // not returning bounds
      // the function is not asychronous, so this was the only fix that seemed to work
      setTimeout(() => {
        const west: number[] = [];
        const south: number[] = [];
        const east: number[] = [];
        const north: number[] = [];
        layers.forEach((layer: any) => {
          const source = mapState.getSource(
            layer.sourceLayer
          ) as MapboxGL.VectorSource;
          const bounds = source.bounds;
          if (bounds) {
            west.push(bounds[0]);
            south.push(bounds[1]);
            east.push(bounds[2]);
            north.push(bounds[3]);
          }
        });
        if (
          west.length > 0 &&
          south.length > 0 &&
          east.length > 0 &&
          north.length > 0
        ) {
          const totalBounds: boundsType = [
            [Math.min(...west), Math.min(...south)],
            [Math.max(...east), Math.max(...north)],
          ];
          if (totalBounds) {
            setBounds(totalBounds);
          }
        }
      }, 100);
    }
  }, [mapId, mapState, layers, isFetchingMap]);

  const resetBounds = () => {
    loadMapBounds();
  };

  useEffect(() => {
    loadMapBounds();
  }, [mapId, mapState, layers, isFetchingMap, loadMapBounds]);

  onMount(() => {
    loadMapData();
  });

  const renderLayers = (layerGroups: LayerGroups) => {
    return <LayerGroupsComponent layerGroups={layerGroups} />;
  };

  const handleClickTracking = (type: string | undefined): void => {
    tracking.trackEvent({
      event: "mouse-click",
      action: `map-click-${type}`,
      mapId: mapId,
    });
  };

  const handleClick = (map: MapboxGL.Map, evt: React.SyntheticEvent) => {
    handleMapClick(map, evt, layers, layerGroups, handleClickTracking);
  };

  const isAnalysisPresent = () => {
    if (
      analysisObj.networkLength !== 0 ||
      analysisObj.networkScore !== 0 ||
      analysisObj.potholeCount !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onStyleLoad = (map: MapboxGL.Map) => {
    updateMapState(map);
  };

  const renderMap = () => (
    <Map
      // eslint-disable-next-line react/style-prop-object
      style={mapStyle}
      containerStyle={{
        height: "100vh",
        width: "100vw",
      }}
      fitBounds={bounds}
      onClick={handleClick}
      onStyleLoad={onStyleLoad}
    >
      <AnalysisDiv analysisObj={analysisObj} />
      {renderLayers(layerGroups)}
      <ZoomControl
        style={{
          marginTop: isAnalysisPresent() ? "70px" : "0px",
        }}
        position="top-left"
      />
      <CenterButton
        isAnalysisPresent={isAnalysisPresent()}
        handleClick={resetBounds}
      />
    </Map>
  );

  return (
    <>
      {mapId ? (
        <MapLoadingIndicator
          isFetchingMap={isFetchingMap}
          isFetchMapSuccessful={isFetchMapSuccessful}
        >
          <RoadBoticsLogo />
          {renderMap()}
          <RatingsHelpModal />
        </MapLoadingIndicator>
      ) : (
        <>
          <p>ERROR: No map data provided</p>
        </>
      )}
    </>
  );
};

export default MapContainer;
