import React from "react";
import { getColor } from "../../utils/layers";
import "./AnalysisDiv.css";
import { meterToMiles } from "../../utils/metrics";

interface AnalysisObjInterface {
  networkLength: number;
  networkScore: number;
  potholeCount: number;
}

interface AnalysisDivProps {
  analysisObj: AnalysisObjInterface;
}

const AnalysisDiv = ({ analysisObj }: AnalysisDivProps) => {
  return (
    <div className="analysis-container">
      {analysisObj.networkScore !== 0 && (
        <div
          className={"analysis-item"}
          style={{
            backgroundColor: getColor(
              parseInt(analysisObj.networkScore.toFixed(0))
            ),
          }}
        >
          <span className="analysis-number">
            {analysisObj.networkScore.toFixed(2)}
          </span>
          <br />
          <span className="analysis-text">Network Score</span>
        </div>
      )}

      {analysisObj.networkLength !== 0 && (
        <div className="analysis-item length">
          <span className="analysis-number">
            {Math.round(meterToMiles(analysisObj.networkLength, 1).scalar)}
          </span>
          <br />
          <span className="analysis-text">Centerline Miles</span>
        </div>
      )}
      {analysisObj.potholeCount !== 0 && (
        <div className="analysis-item pothole">
          <span className="analysis-number">{analysisObj.potholeCount}</span>
          <br />
          <span className="analysis-text">No. Potholes</span>
        </div>
      )}
    </div>
  );
};

export default AnalysisDiv;
