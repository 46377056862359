import Roadlolli from "../images/roadlolli.png";

import Rating1 from "../images/ratings/Rating1.png";
import Rating2 from "../images/ratings/Rating2.png";
import Rating3 from "../images/ratings/Rating3.png";
import Rating4 from "../images/ratings/Rating4.png";
import Rating5 from "../images/ratings/Rating5.png";

export enum MapImages {
  POTHOLE = "Roadlolli"
}

export const MapImageFiles: { [key in MapImages]: string } = {
  [MapImages.POTHOLE]: Roadlolli
};

export const RatingImages: string[] = [
  Rating1,
  Rating2,
  Rating3,
  Rating4,
  Rating5
];
