import React from "react";
import * as MapboxGL from "mapbox-gl";

import { LayerGroupProps } from "./LayerGroups";
import LayerComponent from "../../components/Layer";

const GenericLayerGroup = ({ layers }: LayerGroupProps) => {
  const handlePointMouseEnter = (e: MapboxGL.MapMouseEvent) => {
    const map = e.target;
    map.getCanvas().style.cursor = "pointer";
  };

  const handlePointMouseLeave = (e: MapboxGL.MapMouseEvent) => {
    const map = e.target;
    map.getCanvas().style.cursor = "";
  };

  const renderLayers = () =>
    layers?.length > 0
      ? layers.map((layer, key) => (
          <LayerComponent
            key={key}
            {...layer}
            handleMouseEnter={handlePointMouseEnter}
            handleMouseLeave={handlePointMouseLeave}
          />
        ))
      : null;

  return <>{renderLayers()}</>;
};

export default GenericLayerGroup;
