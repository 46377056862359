import React from "react";
import { Modal } from "react-responsive-modal";
import { MdClose } from "react-icons/md";

import "./popup.css";

interface PopupProps {
  children?: React.ReactNode;
  isOpen?: boolean;
  onCloseModal?: () => void;
}

const defaultCloseHandler = () => {};

const Popup = ({
  children,
  onCloseModal = defaultCloseHandler,
  isOpen = false
}: PopupProps) => (
  <Modal
    open={isOpen}
    onClose={onCloseModal}
    center
    classNames={{
      modal: "rb-popup",
      overlay: "rb-popup-overlay",
      closeButton: "rb-popup-close-button"
    }}
    closeIcon={<MdClose />}
    focusTrapped={false}
  >
    {children}
  </Modal>
);

export default Popup;
