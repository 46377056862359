import React from "react";

import { LayerGroupProps } from "../layer-groups/LayerGroups";
import GenericLayerGroup from "../layer-groups/GenericLayerGroup";

const CrackSeal = ({ layers }: LayerGroupProps) => (
  <GenericLayerGroup layers={layers} />
);

export default CrackSeal;
