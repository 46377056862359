import React, { useState, useEffect, ReactElement } from "react";
import CrossfadeImage from "react-crossfade-image";

import "./SlideShow.css";
import useInterval from "../../hooks/useInterval";
import { preloadImage } from "../../utils/tools";

import {
  MdPlayArrow,
  MdPause,
  MdChevronLeft,
  MdChevronRight,
} from "react-icons/md";

export interface SlideShowSlides {
  image: string;
  details?: {
    icon?: ReactElement;
    value: string;
    title?: string;
  }[];
  primaryDistresses?: {
    hasFatigueCracking: boolean;
    hasPatchSeal: boolean;
    hasPavementDistortions: boolean;
    hasPothole: boolean;
    hasSurfaceDeterioration: boolean;
    hasTransLongCracking: boolean;
  };
  header: React.ReactNode;
}

export interface SlideShowProps {
  slides: SlideShowSlides[];
  initialSlide: number;
  duration?: number;
}

const SlideShow = ({
  slides,
  initialSlide,
  duration = 600,
}: SlideShowProps): ReactElement => {
  const [slide, setSlide] = useState(initialSlide);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const handleClickPlay = (): void => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };

  const handleClickPrevious = (): void => {
    if (
      slides[slide]?.image === slides[slide - 1]?.image &&
      slides[slide - 2]
    ) {
      setSlide(slide - 2);
    } else {
      setSlide(slide - 1);
    }
  };

  const handleClickNext = (): void => {
    if (
      slides[slide]?.image === slides[slide + 1]?.image &&
      slides[slide + 2]
    ) {
      setSlide(slide + 2);
    } else {
      setSlide(slide + 1);
    }
  };

  useInterval(() => {
    if (isPlaying) {
      if (slide < slides.length - 1) {
        setSlide(s => s + 1);
      } else {
        setIsPlaying(false);
      }
    }
  }, duration);

  useEffect(() => {
    // preload the previous and next point's images to make the slide show transition smooth
    if (slide > 0) {
      preloadImage(slides[slide - 1]?.image);
    }
    if (slide < slides.length - 1) {
      preloadImage(slides[slide + 1]?.image);
    }
  }, [slide, slides]);

  const renderSpinner = () => {
    if (!isImageLoading) {
      return null;
    }
    return (
      <div className="spinner-div">
        <div className="spinner" />
      </div>
    );
  };

  const renderSlideImage = () => {
    // I initially wanted to setIsImageLoading(false) on the img's onLoad method
    // but CrossfadeImage doesn't provide the img onLoad method as a prop
    // so I had to use this slightly hacky method to trigger setIsImageLoading(false)
    preloadImage(slides[slide].image, () => setIsImageLoading(false));
    const primaryDistresses = slides[slide].primaryDistresses;
    return (
      <div className="img-wrapper">
        {slides[slide].header && (
          <div className="img-overlay">{slides[slide].header}</div>
        )}
        <div className="slide-image-holder">
          {renderSpinner()}
          <CrossfadeImage
            alt="slide"
            src={slides[slide].image}
            style={isImageLoading ? { display: "none" } : {}}
            duration={400}
            timingFunction="ease-out"
          />
        </div>
        {primaryDistresses ? (
          <div>
            <table className="primary-distresses">
              <tbody>
                <tr id="row0">
                  <td
                    id="potholes"
                    className={
                      primaryDistresses.hasPothole
                        ? "table-cell-selected"
                        : "table-cell"
                    }
                  >
                    Potholes
                  </td>
                  <td
                    id="fatigueCracking"
                    className={
                      primaryDistresses.hasFatigueCracking
                        ? "table-cell-selected"
                        : "table-cell"
                    }
                  >
                    Fatigue Cracking
                  </td>
                  <td
                    id="pavementDistortions"
                    className={
                      primaryDistresses.hasPavementDistortions
                        ? "table-cell-selected"
                        : "table-cell"
                    }
                  >
                    Pavement Distortions
                  </td>
                </tr>
                <tr id="row1">
                  <td
                    id="patchesSealant"
                    className={
                      primaryDistresses.hasPatchSeal
                        ? "table-cell-selected"
                        : "table-cell"
                    }
                  >
                    Patches/Sealant
                  </td>
                  <td
                    id="transLongCracking"
                    className={
                      primaryDistresses.hasTransLongCracking
                        ? "table-cell-selected"
                        : "table-cell"
                    }
                  >
                    Trans/Long Cracking
                  </td>
                  <td
                    id="surfaceDeterioration"
                    className={
                      primaryDistresses.hasSurfaceDeterioration
                        ? "table-cell-selected"
                        : "table-cell"
                    }
                  >
                    Surface Deterioration
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="margin-space" />
        )}
      </div>
    );
  };

  const renderSlideDetails = (
    side: "left" | "right"
  ): (ReactElement | null)[] | null => {
    const [details1, details2, details3, details4] =
      slides[slide].details || [];
    const details =
      side === "left" ? [details1, details2] : [details3, details4];
    return details.map((detail, key) => {
      if (detail) {
        const { icon = "", value, title = "" } = detail;
        return (
          <div className="slide-detail" key={key}>
            {icon && icon}
            <span title={title}>{value}</span>
          </div>
        );
      } else return null;
    });
  };

  const isAtEndOfSlides = slide === slides.length - 1;
  return (
    <div className="slideshow-container">
      {renderSlideImage()}
      <div className="slideshow-details">
        <div className="slideshow-details-section-left">
          {renderSlideDetails("left")}
        </div>
        {slides.length > 1 && (
          <div className="slideshow-controls">
            <button
              disabled={isPlaying || slide === 0}
              onClick={handleClickPrevious}
            >
              <MdChevronLeft style={{ verticalAlign: "middle" }} />
              <span className="hidden-on-xs">PREV</span>
            </button>
            <button
              disabled={isAtEndOfSlides}
              onClick={handleClickPlay}
              className="play-button"
            >
              {isPlaying ? <MdPause /> : <MdPlayArrow />}
            </button>
            <button
              disabled={isPlaying || isAtEndOfSlides}
              onClick={handleClickNext}
            >
              <span className="hidden-on-xs">NEXT</span>
              <MdChevronRight />
            </button>
          </div>
        )}
        <div className="slideshow-details-section-right">
          {renderSlideDetails("right")}
        </div>
      </div>
    </div>
  );
};

export default SlideShow;
