export const preloadImage = (
  imgURL: string,
  callback?: (this: GlobalEventHandlers, ev: Event) => any
) => {
  const img = new Image();
  img.src = imgURL;
  if (callback) {
    img.onload = callback;
  }
};

export const bearingToDirection = (bearing?: number) => {
  if (bearing) {
    if (
      (bearing <= 22.5 && bearing >= 0) ||
      (bearing >= -22.5 && bearing <= 0)
    ) {
      return "North";
    } else if (bearing <= 67.5 && bearing > 22.5) {
      return "North East";
    } else if (bearing <= 110.5 && bearing > 67.5) {
      return "East";
    } else if (bearing <= 157.5 && bearing > 110.5) {
      return "South East";
    } else if (
      (bearing >= -180 && bearing < -157.5) ||
      (bearing <= 180 && bearing > 157.5)
    ) {
      return "South";
    } else if (bearing >= -157.5 && bearing < -110.5) {
      return "South West";
    } else if (bearing >= -110.5 && bearing < -67.5) {
      return "West";
    } else if (bearing >= -67.5 && bearing < -22.5) {
      return "North West";
    }
  } else {
    return undefined;
  }
};
