import * as MapboxGL from "mapbox-gl";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { LayerProps } from "../components/Layer";
import { PointFeatureProperties } from "../containers/point-view-modal/PointViewModal";
import { bearingToDirection } from "./tools";

export const getLayerFromMapPoint = (
  map: MapboxGL.Map,
  layerIds: string[],
  point: MapboxGL.Point
) => {
  const sw: MapboxGL.PointLike = [point.x - 5, point.y - 5];
  const ne: MapboxGL.PointLike = [point.x + 5, point.y + 5];

  const mapFeature = map.queryRenderedFeatures([sw, ne], { layers: layerIds });
  return mapFeature[0];
};

export const findPointIndexInSegment = (
  segmentFeatures: MapboxGL.MapboxGeoJSONFeature[],
  point: MapboxGL.MapboxGeoJSONFeature
) =>
  segmentFeatures.findIndex(
    feature => feature.properties?.timestamp === point.properties?.timestamp
  );

export const findSegmentPointsFromPoint = (
  map: MapboxGL.Map,
  basePoint: MapboxGL.MapboxGeoJSONFeature,
  layers: LayerProps[]
): MapboxGL.MapboxGeoJSONFeature[] => {
  if (basePoint) {
    // grab the first image and get ready
    //preloadImage(clickedPoint?.properties?.href);

    // get all the points which matches the segment of the clicked point
    const segmentFeatures = layers.reduce(
      (features: MapboxGL.MapboxGeoJSONFeature[], layer) => {
        const layerSegmentFeatures = map.querySourceFeatures(layer.id, {
          sourceLayer: layer.sourceLayer,
          filter: basePoint?.properties?.segment && [
            "==",
            "segment",
            basePoint?.properties?.segment,
          ],
        });

        if (layerSegmentFeatures.length > 0) {
          return features.concat(layerSegmentFeatures);
        } else {
          if (basePoint.source === "potholes_layer") {
            return features.concat([basePoint]);
          } else {
            return features;
          }
        }
      },
      []
    );

    // remove certain points which have completely different bearing
    // for eg. if the driver has turned around and is driving in opposite direction
    const filteredSegmentFeatures = segmentFeatures.filter(feature => {
      // make sure the clicked point is included
      if (feature.properties?.timestamp === basePoint.properties?.timestamp) {
        return true;
      }

      // some older scans don't keep track of bearings on their points.
      // In this case, just return true;
      if (!feature.properties?.bearing) {
        return true;
      }

      const bearingDifference = Math.abs(
        basePoint.properties?.bearing - feature.properties?.bearing
      );
      const angleDifference =
        bearingDifference <= 180 ? bearingDifference : 360 - bearingDifference;
      return angleDifference < 90;
    });

    dayjs.extend(isSameOrBefore);
    // sort by timestamp ascending
    filteredSegmentFeatures.sort((f1, f2) =>
      dayjs(f1.properties?.timestamp).isSameOrBefore(
        dayjs(f2.properties?.timestamp)
      )
        ? -1
        : 1
    );

    return filteredSegmentFeatures;
  } else {
    return [];
  }
};

export const transformToPointModalPoints = (
  segmentFeatures: MapboxGL.MapboxGeoJSONFeature[]
): PointFeatureProperties[] => {
  return segmentFeatures.map(({ properties }) => ({
    href: properties?.href,
    timestamp: dayjs(properties?.timestamp).format("MMM D, YYYY"),
    segment: undefined,
    bearing: bearingToDirection(properties?.bearing),
    rating: properties?.rating,
    primaryDistresses: properties?.primaryDistresses
      ? JSON.parse(properties?.primaryDistresses)
      : undefined,
  }));
};
