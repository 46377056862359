import httpClient from "../config/roadwayAPIHttpClient";

export const fetchMapData = async (mapId: string) => {
  const dataFetchURL = `/embedded-maps/data/${mapId}`;
  const dataFetchResponse = await httpClient.get(dataFetchURL);
  return dataFetchResponse.data.data;
};

export const fetchMapLayerData = async (mapId: string, layerId: string) => {
  const dataFetchURL = `/embedded-maps/data/${mapId}/layers/${layerId}`;
  const dataFetchResponse = await httpClient.get(dataFetchURL);
  return dataFetchResponse.data.data;
};

