import React, { useEffect } from "react";

import {
  LayerGroupStore,
  PointModalOpen,
  LineBarOpen,
} from "../../store/store";

import PointViewModal from "./PointViewModal";

const PointViewModalContainer = () => {
  const isPointModalOpen = PointModalOpen.useState(s => s.isOpen);
  const isLineBarOpen = LineBarOpen.useState(s => s.isOpen);
  const points = LayerGroupStore.useState(
    state => state.activePointSegmentFeatureProperties
  );
  const initialPointIndex = LayerGroupStore.useState(
    state => state.activePointSegmentIndex
  );

  const onCloseModal = () => {
    PointModalOpen.update(state => {
      state.isOpen = false;
    });
  };

  const handleAnimationEnd = () => {
    // Ensure the below use effect fires if the same point is clicked again.
    // So reset it to empty array but wait till modal close animation ends
    if (!isPointModalOpen) {
      LayerGroupStore.update(state => {
        state.activePointSegmentFeatureProperties = [];
      });
    }
  };

  useEffect(() => {
    if (points.length > 0) {
      PointModalOpen.update(state => {
        state.isOpen = true;
      });

      if (isLineBarOpen) {
        LineBarOpen.update(state => {
          state.isOpen = false;
        });
      }
    }
  }, [points, isLineBarOpen]);

  return (
    <PointViewModal
      isOpen={isPointModalOpen}
      points={points}
      onCloseModal={onCloseModal}
      initialPointIndex={initialPointIndex}
      handleAnimationEnd={handleAnimationEnd}
    />
  );
};

export default PointViewModalContainer;
