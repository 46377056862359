import React from "react";
import { Layer as MBLayer, Source as MBSource } from "react-mapbox-gl";
import * as MapboxGL from "mapbox-gl";

import { getPaintProps, getLayoutProps } from "../utils/layers";
import LayerDataType from "../constants/layerTypes";
import { LayerLevelPalettes } from "../constants/colors";

export interface Layer {
  id: string;
  name: string;
  source?: object;
  sourceLayer?: string;
  dataType?: LayerDataType;
}

export interface LayerProps extends Layer {
  sourceId?: string;
  type?: string;
  layout?: object;
  paint?: object;
  visible?: boolean;
  level?: number;
  colorPalette?: LayerLevelPalettes;
  handleClick?: (e: MapboxGL.MapMouseEvent, layer: Layer) => void;
  handleMouseEnter?: (e: MapboxGL.MapMouseEvent, layer: Layer) => void;
  handleMouseLeave?: (e: MapboxGL.MapMouseEvent, layer: Layer) => void;
  filter?: any[];
}

const Layer = (layer: LayerProps) => {
  const {
    name,
    source,
    id,
    sourceLayer,
    type,
    level,
    visible = false,
    dataType,
    colorPalette = LayerLevelPalettes.DEFAULT,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    filter
  } = layer;

  const paintProps = getPaintProps(dataType, level, colorPalette);
  const layoutProps = getLayoutProps(dataType, visible);

  const handleClickLayer = (e: MapboxGL.MapMouseEvent) =>
    handleClick && handleClick(e, { sourceLayer, id, name, source, dataType });

  const handleMouseEnterLayer = (e: MapboxGL.MapMouseEvent) =>
    handleMouseEnter &&
    handleMouseEnter(e, { sourceLayer, id, name, source, dataType });

  const handleMouseLeaveLayer = (e: MapboxGL.MapMouseEvent) =>
    handleMouseLeave &&
    handleMouseLeave(e, { sourceLayer, id, name, source, dataType });

  return (
    <>
      <MBSource id={name} geoJsonSource={source} />
      <MBLayer
        type={type}
        id={id}
        sourceLayer={sourceLayer}
        paint={paintProps}
        layout={layoutProps}
        onClick={handleClickLayer}
        onMouseEnter={handleMouseEnterLayer}
        onMouseLeave={handleMouseLeaveLayer}
        filter={filter}
      />
    </>
  );
};

export default Layer;
