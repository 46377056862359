import React from "react";

import logo from "../../images/PoweredByBlackLogo.svg";

import "./roadbotics-logo.css"

const RoadBoticsLogo = () => (
  <a href="https://roadbotics.com" target="_blank" rel="noopener noreferrer">
    <img className="logo" src={logo} alt="logo" />
  </a>
);

export default RoadBoticsLogo
