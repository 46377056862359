export const RB_GREEN = "#1a9641";
export const RB_LIGHT_GREEN = "#a6d96a";
export const RB_YELLOW = "#fad201";
export const RB_ORANGE = "#f26622";
export const RB_RED = "#d7191c";
export const RB_GREY = "#808080";
export const RB_CRACK = "#a8ecd0";
export const RB_SEAL = "#bfa9d1";
export const RB_BLACK = "#000";
export const RB_IMAGE_LOGGER_BLUE = "#548cff";

export enum LayerLevelPalettes {
  DEFAULT = "default",
}

export const COLOR_PALETTES = {
  [LayerLevelPalettes.DEFAULT]: [
    RB_GREEN,
    RB_LIGHT_GREEN,
    RB_YELLOW,
    RB_ORANGE,
    RB_RED,
    RB_GREY,
    RB_CRACK,
    RB_SEAL,
  ],
};
