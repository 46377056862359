import React from "react";

import { largestRemainderRound } from "../../utils/largestRemainderRound";
import { getColor } from "../../utils/layers";

import "./segment-ratings-chart.css";

interface SegmentRatingsChart {
  ratings: number[];
}

const SegmentRatingsChart = ({
  ratings,
}: SegmentRatingsChart): React.ReactElement => {
  const validRatings = ratings.filter(
    rating => rating !== null || isNaN(rating)
  );

  const ratingCounts = validRatings.reduce((counts, rating) => {
    const count = counts.get(rating) || 0;
    counts.set(rating, count + 1);
    return counts;
  }, new Map<number, number>());

  const sortedRatingCounts = new Map([...ratingCounts.entries()].sort());

  const ratingList = Array.from(sortedRatingCounts.keys());
  const ratingPercentages = Array.from(sortedRatingCounts.values()).map(
    count => (count / validRatings.length) * 100
  );

  const roundedRatingPercentages = largestRemainderRound(
    ratingPercentages,
    100
  );
  return (
    <div className="segment-ratings-chart__container">
      {roundedRatingPercentages.map((perc, idx) => (
        <div
          title={`${perc} %`}
          key={idx}
          style={{
            width: `${perc}%`,
            backgroundColor: getColor(ratingList[idx]),
          }}
        ></div>
      ))}
    </div>
  );
};

export default SegmentRatingsChart;
