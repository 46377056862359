import React from "react";

import { LayerProps } from "../../components/Layer";
import Points from "../points/Points";
import Segments from "../segments/Segments";
import CrackSeal from "../crack-seal/CrackSeal";
import Potholes from "../potholes/Potholes";
import LayerType from "../../constants/layerTypes";

import PointViewModalContainer from "../point-view-modal/PointViewModalContainer";

export interface LayerGroups {
  [layerType: string]: LayerProps[];
}

export interface LayerGroupsProps {
  layerGroups?: LayerGroups;
}

export interface LayerGroupProps {
  layers: LayerProps[];
}

const LayerGroups = ({ layerGroups }: LayerGroupsProps) => {
  const renderLayerGroups = () =>
    layerGroups ? (
      <>
        {Object.keys(layerGroups).map(layerType => {
          const layers = layerGroups[layerType];
          switch (layerType) {
            case LayerType.POINT:
            case LayerType.ROADSENSEPOINT:
              return <Points key={layerType} layers={layers} />;
            case LayerType.SEGMENT:
              return <Segments key={layerType} layers={layers} />;
            case LayerType.CRACKSEAL:
              return <CrackSeal key={layerType} layers={layers} />;
            case LayerType.POTHOLE:
              return <Potholes key={layerType} layers={layers} />;

            default:
              return null;
          }
        })}
      </>
    ) : null;

  return (
    <>
      {renderLayerGroups()}
      <PointViewModalContainer />
    </>
  );
};

export default LayerGroups;
