import * as MapboxGL from "mapbox-gl";

import { LayerProps } from "../../components/Layer";
import { LayerGroups } from "../layer-groups/LayerGroups";

import { getLayerFromMapPoint } from "../../utils/map";
import { getLayerDataType } from "../../utils/layers";
import LayerTypes from "../../constants/layerTypes";

import { handlePointClick } from "../points/Points";
import { handleSegmentClick } from "../segments/Segments";
import { handlePotholeClick } from "../potholes/Potholes";

export const handleMapClick = (
  map: MapboxGL.Map,
  evt: React.SyntheticEvent<any>,
  layers: LayerProps[],
  layerGroups: LayerGroups,
  handleClickTracking: (type: string | undefined) => void
) => {
  // Event param is incorrectly typed in react-mapbox-gl.
  // See: https://github.com/alex3165/react-mapbox-gl/issues/776
  const { lngLat, point } = (evt as unknown) as MapboxGL.MapMouseEvent;

  const layerIds = layers.map(layer => layer.id);
  const clickedLayer = getLayerFromMapPoint(map, layerIds, point) || "";
  const layerType = getLayerDataType(layers, clickedLayer.source);

  handleClickTracking(layerType);

  switch (layerType) {
    case LayerTypes.POINT:
    case LayerTypes.CRACKSEAL:
    case LayerTypes.ROADSENSEPOINT:
      handlePointClick(map, layerGroups[layerType], lngLat, clickedLayer);
      break;
    case LayerTypes.POTHOLE:
      handlePotholeClick(map, layerGroups[layerType], clickedLayer);
      break;
    case LayerTypes.SEGMENT:
      handleSegmentClick(map, lngLat, clickedLayer);
  }
};
