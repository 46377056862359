import { Store } from "pullstate";

import { PointFeatureProperties } from "../containers/point-view-modal/PointViewModal";
import { SegmentFeatureProperties } from "../components/segment-info-bar/SegmentInfoBar";

export interface LayerGroupStoreInterface {
  activePointSegmentFeatureProperties: PointFeatureProperties[];
  activePointSegmentIndex: number;
  activeSegmentProperties: SegmentFeatureProperties;
}

export const LayerGroupStore = new Store<LayerGroupStoreInterface>({
  activePointSegmentFeatureProperties: [],
  activePointSegmentIndex: 0,
  activeSegmentProperties: {},
});

export interface LineBarOpenInterface {
  isOpen: boolean;
}

export const LineBarOpen = new Store<LineBarOpenInterface>({
  isOpen: false,
});

export interface PointModalOpenInterface {
  isOpen: boolean;
}

export const PointModalOpen = new Store<PointModalOpenInterface>({
  isOpen: false,
});
